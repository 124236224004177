import React, { act, useCallback, useEffect, useState } from "react";
import logo from "./logo.svg";
import { HomeComponent } from "./components/Home/Home";
import { BlasterComponent } from "./components/BlasterComponent/BlasterComponent";
import {
  AnimatePresence,
  motion,
  useAnimate,
  useAnimationControls,
  Variants,
} from "framer-motion";
import { usePrevious } from "@uidotdev/usehooks";
import { ShockerComponent } from "./components/ShockerComponent/ShockerComponent";
function App() {
  const animateHome = useAnimationControls();
  const animateBlaster = useAnimationControls();
  const animateShocker = useAnimationControls();

  const pages = [animateHome, animateBlaster, animateShocker];
  const [actualPage, setActualPage] = useState(0);
  const previousPage = usePrevious(actualPage);
  const [scrolling, setScrolling] = useState(false);
  const variants: Variants = {
    fadeInDown: {
      y: 0,
      opacity: 1,
      pointerEvents: "all",
      transition: { type: "spring", bounce: 0.1, duration: 2 },
    },
    fadeInUp: {
      y: 0,
      opacity: 1,
      pointerEvents: "all",
      transition: { type: "spring", bounce: 0.1, duration: 2 },
    },
    fadeOutDown: {
      opacity: 0,
      pointerEvents: "none",
      y: 1000,
    },
    fadeOutUp: {
      opacity: 0,
      pointerEvents: "none",
      y: -1000,
    },
  };

  useEffect(() => {
    animateHome.start("fadeInDown");
  }, []);

  useEffect(() => {
    document.addEventListener("wheel", handleScroll);
    return () => document.removeEventListener("wheel", handleScroll);
  }, [actualPage, scrolling]);

  useEffect(() => {
    if (previousPage === null) return;
    if (actualPage > previousPage) {
      pages[previousPage].start("fadeOutUp");
      pages[actualPage].set("fadeOutDown");
      pages[actualPage].start("fadeInUp");
    } else {
      pages[previousPage].start("fadeOutDown");
      pages[actualPage].set("fadeOutUp");
      pages[actualPage].start("fadeInDown");
    }
  }, [actualPage, previousPage]);

  const handleScroll = useCallback(
    (a: { deltaY: number }) => {
      console.log(a);
      if (scrolling) return;
      const sum = a.deltaY > 0 ? 1 : -1;
      if (actualPage + sum >= 0 && actualPage + sum < pages.length) {
        setActualPage(actualPage + sum);
        setScrolling(true);
        setTimeout(() => setScrolling(false), 1000);
      }
    },
    [actualPage, scrolling]
  );

  return (
    <div className="App overflow-hidden h-screen w-screen">
      <AnimatePresence>
        <motion.section
          initial={{ opacity: 0, pointerEvents: "none" }}
          variants={variants}
          animate={animateHome}
          transition={{ duration: 0.5, ease: "easeInOut", bounce: 1 }}
          className="flex flex-col w-screen h-screen fixed top-0"
        >
          <HomeComponent />
        </motion.section>
      </AnimatePresence>

      <AnimatePresence>
        <motion.section
          initial={{ opacity: 0, pointerEvents: "none" }}
          variants={variants}
          animate={animateBlaster}
          transition={{ duration: 0.5, ease: "easeInOut", bounce: 1 }}
          className="flex flex-col w-screen h-screen fixed top-0 bg-slate-400"
        >
          <BlasterComponent rendering={actualPage === 1} />
        </motion.section>
      </AnimatePresence>

      <AnimatePresence>
        <motion.section
          initial={{ opacity: 0, pointerEvents: "none" }}
          variants={variants}
          animate={animateShocker}
          transition={{ duration: 0.5, ease: "easeInOut", bounce: 1 }}
          className="flex flex-col w-screen h-screen fixed top-0 bg-slate-400"
        >
          <ShockerComponent rendering={actualPage === 2} />
        </motion.section>
      </AnimatePresence>
    </div>
  );
}

export default App;
