import { useCallback, useEffect, useState } from "react";
import "./Home.css";
import background1 from "../../img/blastarena.png";
import background2 from "../../img/blastarena2.png";

export const HomeComponent = () => {
  const backgrounds = [background1, background2];
  const [actualBackground, setActualBackground] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (actualBackground + 1 < backgrounds.length) {
        setActualBackground(actualBackground + 1);
      } else {
        setActualBackground(0);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [actualBackground]);

  const handleDownloadClick = useCallback(() => {
    window.open(`https://${window.location.hostname}:3005/download_launcher`);
  }, []);

  return (
    <div className="bodyWrapperBackgroundClient flex absolute w-screen h-screen icebergText select-none">
      <div className="flex flex-col justify-center content-center w-[70%] h-full ">
        <div className="flex basis-1/12 mx-auto w-50 text-white justify-end align-middle mb-12 top-3 relative">
          <h2 className="justify-center align-middle self-end text-5xl tracking-wide ">
            BLAST ARENA
            <span className="relative bottom-2 ml-1 animate-pulse">_</span>{" "}
            <span className="text-xs block">Fast paced Arena Action</span>
          </h2>
        </div>
        <div
          className="flex mainBackgroundDiv basis-9/12 mb-10"
          style={{ backgroundImage: `url(${backgrounds[actualBackground]})` }}
        />
        <div className="flex align-middle justify-center basis-1/12">
          <div
            className="playForFreeButton flex w-auto basis-1/4 grow-0"
            onClick={handleDownloadClick}
          />
        </div>
      </div>
    </div>
  );
};
