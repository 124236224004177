//@ts-ignore
import video from "../../video/shockerweapon.mp4";
import img from "../../img/ShockerPlayer.png";
import { AnimatePresence, motion, Transition } from "framer-motion";
export const ShockerComponent = (props: { rendering: boolean }) => {
  const animationConfig: Transition = {
    type: "spring",
    damping: 40,
    mass: 10,
  };
  return (
    <div className="flex w-screen h-screen align-middle justify-center text-white icebergText select-none">
      <div className="grid grid-cols-7 grid-rows-8 w-full p-5">
        <div className="row-span-1 col-span-1 col-start-4 flex justify-center align-middle">
          <h1 className="text-5xl animate-pulse">The Shocker</h1>
        </div>
        <AnimatePresence>
          {props.rendering && (
            <motion.div
              initial={{ x: -1000 }}
              animate={{ x: 0 }}
              exit={{ x: -1000 }}
              transition={animationConfig}
              className="grid grid-cols-3 grid-rows-8 row-span-5 row-start-2 col-span-2 bg-slate-600 rounded-md p-5 col-start-2"
            >
              <div className="col-start-1 col-span-3">
                <h1 className="text-3xl animate-pulse">The Weapon</h1>
              </div>
              <div className="flex col-start-1 row-span-4 col-span-3 bg-slate-900 justify-center align-middle">
                <video width="auto" height="auto" autoPlay loop={true} muted>
                  <source type="video/mp4" src={video} />
                </video>
              </div>
              <div className="p-2 col-span-3">
                <p className="px-2 w-full">
                  <span className="font-bold text-xl text-yellow-200 animate-pulse tracking-wide">
                    ID: Shock.IO - #S-0-99X
                  </span>{" "}
                  <br />
                  Offensive , shock producing device. <br />
                  Produced by Shock.IO Corp. since the year 22XX. <br /> <br />
                  <span className="font-bold text-red-500 animate-pulse tracking-wide duration-75">
                    DEFFECT FOUND
                  </span>
                  <p>
                    Shocks can provoke serious injury, high amounts of force or
                    death.
                  </p>
                </p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {props.rendering && (
            <motion.div
              initial={{ x: 1000 }}
              animate={{ x: 0 }}
              exit={{ x: 1000 }}
              transition={animationConfig}
              className="grid col-start-5 grid-cols-3 grid-rows-8 row-span-5 row-start-2 col-span-2 bg-slate-600 rounded-md p-5"
            >
              <div className="col-start-1 col-span-3">
                <h1 className="text-3xl animate-pulse">The Player</h1>
              </div>
              <div className="flex col-start-1 row-span-4 col-span-3 bg-slate-900 justify-center align-middle">
                <img width="auto" height="auto" src={img}></img>
              </div>
              <div className="p-2 col-span-3">
                <p className="px-2 w-full">
                  <span className="font-bold text-xl text-yellow-200 animate-pulse tracking-wide">
                    ID: Shock.IO - #SBOT-DD-90
                  </span>{" "}
                  <br />
                  High speed, fast paced, remote controlled Bot. <br />
                  Produced by Shock.IO Corp. since the year 22XX. <br />
                  <span className="font-bold text-red-500 animate-pulse tracking-wide">
                    TRAITS
                  </span>
                  <p>
                    - Dash <br />
                    - Double Jump <br />
                    - Double Jump Dash <br />
                  </p>
                </p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {props.rendering && (
            <motion.div
              initial={{ y: 500 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ ease: "easeInOut", duration: 1 }}
              className="row-span-2 col-span-5 bg-slate-600 col-start-2 m-5 rounded-lg p-5"
            >
              <p>
                - Blaster Players move defensively at a fast pace while trying
                to produce BlastBalls for the Shocker Player to explode. <br />-
                Blasters can produce BlastBalls and launch them at diverse speed
                through the arena. <br />- Blasters are recommended to stay away
                from battle , as they don't possess the ability to explode enemy
                Blast Balls. <br />- Blasters must maintain comms with Shockers
                in their team to place well timed attacks.
              </p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};
